/* 06.01. Home One Intro CSS */
.intro-slider-wrap {
    background-color: $white;
    padding: 0 60px;
    @media #{$xxl-device} {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$lg-device} {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media #{$xs-device} {
        padding-left: 15px;
        padding-right: 15px;
    }
    .swiper-pagination {
        position: absolute;
        bottom: 40px;
    }
}
.intro-section {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    height: 845px;
    padding: 50px 0;
    // Responsive
    @media #{$xxl-device} {
        height: 600px;
    }
    @media #{$lg-device} {
        height: 500px;
    }
    @media #{$md-device} {
        height: 450px;
    }
    @media #{$xs-device} {
        height: 450px;
    }
    @media #{$xxs-device} {
        height: 400px;
    }

    & .container {
        z-index: 9;
    }

    // @media #{$tablet-device} {
    //     height: 550px;
    // }
    // @media #{$large-mobile} {
    //     height: 500px;
    // }
}

.intro-content {
    text-align: center;
    & .sub-title {
        display: block;
        font-size: 24px;
        line-height: 1.3;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 20px;
        margin-bottom: 40px;
        // Responsive
        @media #{$lg-device} {
            font-size: 18px;
            letter-spacing: 14px;
        }
        @media #{$md-device} {
            font-size: 18px;
            letter-spacing: 14px;
        }
        @media #{$xs-device} {
            font-size: 16px;
            letter-spacing: 10px;
        }
        @media #{$xxs-device} {
            font-size: 16px;
            letter-spacing: 10px;
            margin-bottom: 20px;
        }
    }
    & .title {
        font-size: 58px;
        line-height: 1.2;
        color: $white;
        font-family: $secondary-font;
        margin-bottom: 30px;
        // Responsive
        @media #{$lg-device} {
            font-size: 44px;
        }
        @media #{$md-device} {
            font-size: 40px;
        }
        @media #{$xs-device} {
            font-size: 36px;
        }
        @media #{$xxs-device} {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }

    & .desc {
        & p {
            font-size: 14px;
            line-height: 1.67;
            color: $white;
        }
    }

    & .intro-btn {
        display: block;
        margin-top: 80px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        text-decoration: underline;
        // Responsive
        @media #{$lg-device} {
            margin-top: 40px;
        }
        @media #{$md-device} {
            margin-top: 40px;
        }
        @media #{$xxs-device} {
            font-size: 16px;
            margin-top: 20px;
        }
    }
}

.intro-content {
    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}

.intro-slider {
    .main-slider-nav {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.25);
        width: 100px;
        height: 100px;
        line-height: 100px;
        display: block;
        text-align: center;
        color: $white;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        &::after {
            display: none;
        }
        & i {
            font-size: 40px;
            line-height: 1;
            vertical-align: middle;
        }
        &:hover {
            color: red;
        }
    }
    .home-slider-prev {
        left: 30px;
    }
    .home-slider-next {
        right: 30px;
        &::after {
            display: none;
        }
    }
    &:hover {
        .main-slider-nav {
            opacity: 1;
            visibility: visible;
        }
    }
}
.swiper-slide-active {
    & .intro-content {
        // All Element Selector
        & > * {
            animation-name: fadeInUp;

            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.5s;
            }
            &:nth-child(4) {
                animation-delay: 2s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
        }
    }
}

.intro-slider-wrap {
    & .swiper-pagination {
        position: absolute;
        bottom: 40px;
        z-index: 999999;
        width: 100%;
        text-align: center;
    }
}
