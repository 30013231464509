.dashboard_Component{
   .dashboard_header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 190px;
    font-weight: bold;
   }
   .dashboard_box{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.308) 0px 3px 8px;
    height: 458px;
    border: 1px solid white;
    margin-top: 60px;
   }
}