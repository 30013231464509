.createBooking_Component {
  .createBooking_header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    font-weight: bold;
  }
  .createBooking-div {
    margin-top: 150px;
    border-radius: 30px;
  }
  .createBooking-radius {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .accordion-button:not(.collapsed),
  .accordion-button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }
  .accordion-button::after {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: var(--bs-danger);
    background-image: none !important;
  }
  .accordion-button.collapsed::after {
    background-color: var(--bs-gray-300);
  }
  .accordion-button::after {
    display: none;
  }
  .accordion_div{
   margin-bottom: 190px;
  }
  .booking{
    border: 1px solid rgba(128, 128, 128, 0.416);
    padding: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  }
  .confirm_booking{
    margin-top: 50px;
  }

  .card-payment {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 50px auto;
}
.card-payment .card-details {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.card-payment .total {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-align: center;
}
.card-payment .edit-link, .card-payment .add-card {
    font-size: 12px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}
.card-payment .mastercard-logo {
    float: right;
}

.card-element-input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px; /* Add padding to make it look like an input */
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  display: block;
  width: 100%; /* Ensure full width like an input field */
  height: 40px;
}

.card-element-input:hover {
  border-color: #80bdff;
}

.card-element-input:focus-within {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.payment-success-container {
  max-width: 900px;
  margin: 50px auto;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.success-icon {
  width: 100px;
  height: 100px;
  background-color: #d4f5d3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px auto;
}
.success-icon .checkmark {
  font-size: 36px;
  color: #28a745;
}
.payment-details h5 {
  font-weight: bold;
  margin-bottom: 10px;
}
.payment-details .amount-paid {
  color: #28a745;
  font-weight: bold;
}
.payment-details .payment-method {
  color: #007bff;
  font-weight: bold;
}
}
