/*
-----------------------------------------------------------------------
  Icon Box CSS
-----------------------------------------------------------------------
*/
.icon-box-style1 {
    .icon-box-item {
        display: flex;
        margin-bottom: 96px;
        position: relative;
        @media #{$lg-device} {
            margin-bottom: 60px;
        }
        @media #{$xs-device} {
            margin-bottom: 50px;
        }

        .icon {
            color: $theme-color;
            font-size: 72px;
            float: left;
            line-height: 1;
            margin-right: 26px;
            @include transition(0.3s);
        }

        .content {
            font-size: 14px;
            .title {
                font-size: 16px;
                margin-bottom: 16px;
                text-transform: uppercase;
                @media #{$xs-device} {
                    font-size: 14px;
                    margin-bottom: 12px;
                }
                a {
                    color: $black-0e;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
            p {
                font-size: 13px;
                line-height: 24px;
                @include transition(0.3s);
            }
            .btn-theme {
                margin-top: 10px;
            }
            .btn-link {
            }
        }
        &:hover {
            .content {
                p {
                    color: $black;
                }
            }
        }
    }
}

.icon-box-style2 {
    .icon-box-item {
        position: relative;
        z-index: 0;
        &:before {
            background-color: $white;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }
}
