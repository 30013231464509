.bookingList_Component {
    .createBooking_header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 90px;
      font-weight: bold;
    }
    .bookingList_div {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      border-radius: 30px;
      height: 640px;
      border: 1px solid white;
      padding-top: 20px;
    }
  }