/*
-----------------------------------------------------------------------
	Form CSS
-----------------------------------------------------------------------
*/

.input-btn-group {
    align-items: center;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0px 35px 100px 0px rgba(171, 181, 189, 0.35);
    display: flex;
    padding: 5px 5px 5px 0;
    .form-control {
        border: none;
        border-radius: 0;
        border-right: 2px solid #eaeaea;
        height: 24px;
        padding-left: 30px;

        @include placeholder {
            color: #aba5a5;
        }

        &.no-border {
            border: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
    .btn-theme {
        border-radius: 5px;
        height: 50px;
    }
}

.contact-form {
    .form-group {
        margin-bottom: 30px;
        @media #{$sm-device} {
            margin-bottom: 15px;
        }
        .form-control {
            background-clip: inherit;
            border: 1px solid #dfdfdf;
            border-radius: 2px;
            color: $black;
            font-size: 14px;
            height: 45px;
            letter-spacing: 0;
            padding: 12px 19px;
            @include placeholder {
                color: $black-999;
            }
            &:focus {
                border: 1px solid $black;
                box-shadow: none;
                outline: none;
            }
        }
        textarea {
            border: 1px solid #dfdfdf;
            border-radius: 2px;
            font-size: 14px;
            min-height: 200px;
            padding: 12px 19px;
            width: 100%;
            @include placeholder {
                color: $black-999;
            }
            &:focus {
                border: 1px solid $black;
                box-shadow: none;
                outline: none;
            }
        }
        .btn-submit {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $black-999;
            border-radius: 0;
            color: $black-999;
            font-size: 18px;
            font-weight: $font-weight-bold;
            letter-spacing: 0.25px;
            min-height: auto;
            padding: 57px 0 5px;
            position: relative;
            height: auto;
            text-transform: uppercase;
            @include transition(0.3s);
            @media #{$lg-device} {
                font-size: 16px;
                padding: 45px 0 5px;
            }
            @media #{$md-device} {
                border-bottom-width: 1px;
                font-size: 14px;
                padding: 35px 0 5px;
            }
            @media #{$sm-device} {
                padding: 20px 0 5px;
            }
            &:after {
                background-color: $black;
                bottom: -2px;
                content: "";
                height: 2px;
                right: 0;
                position: absolute;
                width: 0;
                @include transition(0.5s);
                @media #{$md-device} {
                    bottom: -1px;
                    height: 1px;
                }
            }
            &:hover,
            &:focus {
                color: $black;
                &:after {
                    left: 0;
                    width: 100%;
                }
            }
        }
        p {
            color: red;
        }
    }
}

// Comment Form Style
.comment-form-wrap {
    form {
        .comment-form-content {
            .form-group {
                margin-bottom: 30px;
                .form-control {
                    border: none;
                    border-radius: 0;
                    font-size: 14px;
                    height: 49px;
                    padding: 7px 21px;
                    @include placeholder {
                        color: $black-999;
                    }
                    &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                    }
                }
                textarea {
                    &.form-control {
                        min-height: 217px;
                    }
                }
                .btn-theme {
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    letter-spacing: 0.25px;
                    margin-top: 17px;
                    min-height: 60px;
                    padding: 10px 28.96px;
                }
            }
        }
    }
}

.form-message {
    overflow: hidden;
    @include transition(0.3s);
    button {
        &.btn-close {
            border: none;
            box-shadow: none;
            background-color: transparent;
            float: right;
            font-size: 14px;
            margin-top: 0;
            text-indent: 1000px;
        }
    }
}
